<template>
  <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="true" ref="authenticModal" aria-labelledby="authenticModalLabel" aria-hidden="true" >
    <div :class="['modal-dialog modal-dialog-centered', modalSizeClass]" role="document">
      <div class="modal-content">
        <div class="modal-header bg-transparent border-0">
          <h5 class="modal-title" id="authenticModalLabel">{{ title }}</h5>
          <span class="close-icon" @click="cancelClicked" aria-label="Close">
             <img :src="getIcon" alt="Close Icon" />
          </span>
        </div>
        <div class="modal-body">
          <slot v-if="isMounted"></slot>
        </div>
        <div v-if="!hideFooter && isMounted" class="modal-footer">
          <slot name="modal-footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { Modal } from 'bootstrap';

// Props definition
const props = defineProps<{
  id: string;
  title?: string;
  size?: string;
  hideFooter?: boolean;
  noCloseOnBackdrop?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}>();

defineExpose({
  showModal,
  hideModal
});

const getIcon = new URL('@/assets/images/app/CloseCircle.svg', import.meta.url).href;

const authenticModal = ref<HTMLElement | null>(null);
const modalTriggerButton = ref<HTMLButtonElement | null>(null);
const isMounted = ref(false);
let modalInstance: Modal | null = null;

// Computed property for modal size
const modalSizeClass = computed(() => {
  return props.size ? `modal-${props.size}` : 'modal-lg';
});

onMounted(() => {
  if (authenticModal.value) {
    modalInstance = new Modal(authenticModal.value, {
      backdrop: props.noCloseOnBackdrop ? 'static' : true,
      keyboard: !props.noCloseOnBackdrop,
    });
  }
});

function showModal(){
  isMounted.value = true; 
  modalInstance?.show();
}

function hideModal(){
  if (modalInstance) {
    modalInstance.hide();
  }
  setTimeout(() => {
    isMounted.value = false;  
  }, 300);  // Delay slightly to allow closing animation
}


// Ok and cancel methods
function onSavedClicked() {
  if(props.onSubmit) {
    props.onSubmit?.();
  }
}

function cancelClicked() {
  props.onCancel?.();
  hideModal();
}
</script>

<style scoped>

/* .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
} */

.close-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31px; 
  height: 31px;
  background-color: transparent; 
  border-radius: 50%; 
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden; 
  position: absolute; 
  top: -12px; 
  left: -12px; 
}


@media screen and (max-width: 576px) {
  :deep .modal-content {
    height: 80% !important;
    width: 90% !important;
    left: 15px !important;
    z-index: 1000;
  }

  :deep .modal-body {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) {
  :deep .modal-content {
    height: 80% !important;
    width: 100% !important;
    left: 15px !important;
    z-index: 1000;
  }

  :deep .modal-body {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {

  /* :deep .modal-content {
  height:80% !important;
  width:100% !important;
  left:15px !important;
} */
  :deep .modal-body {
    overflow-y: scroll;
  }
}
</style>
