<template>
  <div :class="containerClass">
    <img id="product-image" :src="imagePreview" class="img-fluid d-block" :class="imageClass" />

    <div v-if="upLoadButton && !inline && fromSummary">
      <button class="btn btn-primary btn-sm mb-2 p-0" :class="buttonClass" @click="getGallery('image')">
        <i class="bi bi-plus" style="font-size: 1rem;"></i>
      </button>
      <input type="file" id="image" ref="listImage" accept="image/*" @change="previewImage" style="display: none" />
    </div>

    <div v-if="upLoadButton && !inline && !fromSummary">
      <button class="btn btn-primary btn-sm mb-2 p-0" :class="buttonClass" @click="triggerFileUpload">
        <i class="bi bi-plus" style="font-size: 1rem;"></i>
      </button>
      <input type="file" id="image" ref="listImage" accept="image/*" @change="previewImage" style="display: none" />
    </div>

    <div v-if="upLoadButton && inline">
      <button class="btn btn-primary btn-sm mb-2 p-0" :class="buttonClass" @click="triggerFileUpload">
        <i class="bi bi-plus" style="font-size: 1rem;"></i>
      </button>
      <input type="file" id="image" ref="listImage" accept="image/*" @change="previewImage" style="display: none" />

    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, inject } from 'vue';

  const emit = defineEmits(['change']);
  const emitter: any = inject("emitter");

  const props = defineProps<{
    imageSrc?: string;
    size?: string;
    buttonLocation?: string;
    upLoad?: boolean;
    inline?: boolean;
    fromSummary?: boolean;
  }>();

  const imageFile = ref<File | null>(null);
  const upLoadButton = ref(false);
  const iconScale = ref("1.75");
  const selectedImage = ref("");

  defineExpose({
    imageFile,
  });

  const imagePreview = computed(() => {
    return selectedImage.value ? selectedImage.value : props.imageSrc;
  });

  const containerClass = computed(() => {
    return props.size ? `image-container-${props.size}` : "image-container-lg";
  });

  const imageClass = computed(() => {
    return props.size ? `image-${props.size}` : "image-lg";
  });

  const buttonClass = computed(() => {
    return props.buttonLocation
      ? `btn-circle-${props.size}-${props.buttonLocation}`
      : "btn-circle-lg-center";
  });

  onMounted(() => {
    setParams();
  });

  function setParams() {
    upLoadButton.value = props.upLoad;
    if (props.size === "sm") {
      iconScale.value = "1.5";
    }
  }

  function getGallery(type: string) {
    emitter.emit("media-select", type);
  }

  function triggerFileUpload() {
    const inputElement = (document.getElementById("image") as HTMLInputElement);
    if (inputElement) {
      inputElement.click();
    }
  }

  function previewImage(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        selectedImage.value = e.target?.result as string;
        emit("change", input.files?.[0]);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  
</script>

<style scoped>
.bi-plus::before {
  transform: scale(1.6);
}

.image-lg {
  border: 0;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  margin: auto;
}

.image-container-lg {
  display: flex;
  position: relative;
  height: 136px;
  width: 136px;
  background-color: #898989;
  border-radius: 0.5rem;
  align-items: center;
}

.image-sm {
  border: 0;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  margin: auto;
}

.image-container-sm {
  display: flex;
  position: relative;
  width: 72px;
  height: 72px;
  background-color: #898989;
  border-radius: 0.5rem;
  align-items: center;
}

.image-xs {
  border: 0;
  background-color: #f9f9f9;
  height: 32px;
  width: auto;
  margin: auto;
}

.image-container-xs {
  display: flex;
  position: relative;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  align-items: center;
}

.btn-circle-lg-center {
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-lg-ul {
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 0;
  position: absolute;
  top: -7%;
  left: -7%;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
}

.btn-circle-sm-center {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-xs-right {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-circle-sm-ul {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  line-height: 0;
  position: absolute;
  top: -7%;
  left: -7%;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
}
</style>
