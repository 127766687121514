<template>
  <div class="container">
    <div class="card border-0">
      <div class="card-body">
      <div class="row pb-2">
        <div class="col">
          <button type="button" class="btn btn-outline-primary btn-sm rounded-pill new-user" @click="inviteUser">{{
            actionTitle }}</button>
        </div>
      </div>

      <div class="row" v-if="hasSearch">
        <slot name="user-search">
          <div class="input-group px-0">
            <div class="input-group-prepend">
              <button type="button" class="btn btn-outline-secondary search-button" flip-h @click="
                  page = 1;
                  getUsers();
                ">
                <i class="bi bi-search" style="color:#898989"></i>
              </button>
            </div>
            <input type="search" v-model="search" class="form-control search" placeholder="Search by user name..."
              @keydown.enter="
                page = 1;
                getUsers();
              " @input="resetSearch()" />
          </div>
          <div class="form-group text-center">
            <div class="form-check form-check-inline">
              <input class="form-check-input me-2" type="radio" name="user-list" value="alpha" checked
                @change="sortArray($event)" />
              <label class="form-check-label" for="user-list">Alphabetic</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input me-2" type="radio" name="user-list" value="recent" @change="sortArray($event)" />
              <label class="form-check-label" for="user-list">Recently</label>
            </div>
          </div>
        </slot>
      </div>

      <div class="row">
        <slot name="user-sort"></slot>
      </div>


      <div class="row company-table my-3 px-0">
        <div class="col">
          <table id="table" class="table table-sm table-hover">
            <thead class="table-header" style="z-index: 1;">
              <tr>
                <th class="labels table-header" scope="col">Name</th>
                <th class="labels table-header" scope="col">Email</th>
                <th class="labels table-header" scope="col">Role</th>
                <th class="labels table-header" scope="col">Status</th>
                <th class="labels table-header" scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(companyUser, index) in users" :key="index" @click="rowClicked(user, index)">

                <td style="z-index: 0;">
                  <div class="d-flex">
                    <input type="text" v-if="selectedRow[index]" v-model="companyUser.name"
                      class="form-control edit-text limit-column-width" />
                    <span v-else>{{ companyUser.name }}</span>
                  </div>
                </td>

                <td style="z-index: 0;">
                  <div class="d-flex">
                    <input type="text" v-if="selectedRow[index]" v-model="companyUser.email"
                      class="form-control edit-text" />
                    <span v-else>{{ companyUser.email }}</span>
                  </div>
                </td>

                <td style="z-index: 0;">
                  <div class="d-flex">
                    <select v-if="selectedRow[index]" v-model="companyUser.role"
                      class="form-control form-select form-select-sm  edit-select">
                      <option v-if="user.companyId === 1 && user.role === 'Superadmin'" value="Superadmin">Superadmin
                      </option>
                      <option value="Admin">Admin</option>
                      <option value="User">User</option>
                    </select>
                    <span v-else>{{ companyUser.role }}</span>
                  </div>
                </td>

                <td style="z-index: 0;">
                  <div class="d-flex ps-3">
                    <i v-if="companyUser.status === 'Inactive'" class="bi bi-x-circle"
                      style="font-size: 1em; color:#e3342f"></i>
                    <i v-if="companyUser.status === 'Active'" class="bi bi-check-circle"
                      style="font-size: 1em; color:#007bff"></i>
                    <span class="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="left" title="Pending invite">
                      <i v-if="companyUser.status === 'Pending'" class="bi bi-exclamation-circle"
                        style="font-size: 1em; color:#6c757d">
                      </i>
                    </span>
                  </div>
                </td>

                <td style="z-index: 0;">
                  <div v-if="!selectedRow[index]" class="btn-group">
                    <button type="button" class="btn action-button py-0 px-1" @click.stop="editSelectedRow(index)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit user">
                      <i class="bi bi-pencil-square" style="font-size: 1em; color:#007bff"></i>
                    </button>
                    <button type="button" class="btn action-button py-0 px-1"
                      @click.stop="resendInvite(index, companyUser)" data-bs-toggle="tooltip" data-bs-placement="top" :title="resend">
                      <i class="bi bi-envelope-arrow-up" style="font-size: 1em; color:#6c757d"></i>
                    </button>
                    <button type="button" class="btn action-button py-0 px-1"
                      @click.stop="suspendUser(index, companyUser)" data-bs-toggle="tooltip"  title="Suspend user">
                      <i class="bi bi-x-octagon" style="font-size: 1em; color:#e3342f"></i>
                    </button>
                  </div>
                  <div v-else class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-outline-secondary btn-sm rounded-pill me-2"
                      @click.stop="cancelUpdate(index)">Cancel</button>
                    <button type="button" class="btn btn-primary btn-sm rounded-pill"
                      @click.stop="_updateUser(index, companyUser)">Save</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row justify-content-between mt-4">
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto">
              <label class="rows">Rows per page:</label>
            </div>
            <div class="col-auto ps-0">
              <select id="page-select" class="form-select" @change="changeNumberOfRowsPerPage"
                aria-label="Default select example">
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="15">15</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage">Previous</button>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                <button class="page-link" @click="goToPage(page)">{{ page }}</button>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="nextPage">Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <InviteUser />
    </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchUsers } from "../../services/CompanyService";
import User from "../../models/User";
import InviteUser from "../administration/InviteUser.vue";
import { updateUser } from "../../services/UserService";
import { createUser } from "../../services/AdminService";
import isEmpty  from "lodash/isEmpty";
import { onBeforeUnmount, onMounted, ref, inject, computed } from "vue";
import { Tooltip } from "bootstrap";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

  const props = defineProps<{
    actionTitle: string;
    fields: Array<object>;
    hasSearch: boolean;
  }>();

  const emitter: any = inject("emitter");
  const toast = useToast();
  const $store = useStore();
  const tooltipInstances = ref<InstanceType<typeof Tooltip>[]>([]);

  const resend = computed(() => {
    return "Resend invitation";
  });

  const rows = computed(() => {
    return resultsReturned;
  });
  const company = computed(() => {
    return $store.getters.company;
  });
  const user = computed((): User => {
    return $store.getters.authUser;
  });
  const totalPages = computed(() => { 
        return Math.max(1, Math.ceil(resultsReturned.value / rowsPerPage.value));
  });

  const totalUsers = ref(0);
  const page = ref(1);
  const currentPage = ref(1);
  const rowsPerPage = ref(10);
  const resultsReturned = ref(0);

  const users = ref<User[]>([]);
  const userSortSelected = ref("alpha");
  const updateTable = ref(0);
  const userCount = ref(0);
  // selectedRow: {[key:string]: any} = {}
  const selectedRow = ref<{[key:string]: any}>({});
  const selectedRole = ref("User");
  const userName = ref("");
  const search = ref("");

  onMounted(async() => {
    const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipElements.forEach((element) => {
      const tooltip = new Tooltip(element as HTMLElement, { trigger: "hover" });
      tooltipInstances.value.push(tooltip);
    });
    getUsers();
    emitter.on("newuserinvite", () => {
      getUsers();
    });
  })

  function updateUsers(response: {
    users: User[];
    currentPage: number;
    userCount: number;
    resultsReturned: number;
  }) {
    users.value = response.users;
    currentPage.value = response.currentPage;
    resultsReturned.value = response.resultsReturned;
    userCount.value = response.userCount;
    updateTable.value += 1;
  }

  function setPagingParams(search: string, page: number, perPage: number, sort: string) {
    const params: any = {};
    if (search) {
      params["search"] = search;
    }
    if (page) {
      params["page"] = page;
    }
    if (perPage) {
      params["size"] = perPage;
    }
    if (sort) {
      params["sort"] = sort;
    }
    return params;
  }

  async function getUsers() {
    const params = setPagingParams(
      search.value,
      page.value,
      rowsPerPage.value,
     userSortSelected.value
    );
    const fetchResponse = await fetchUsers(company.value.companyId, params);
    updateUsers(fetchResponse);
  }

  function resetSearch() {
    if (isEmpty(search)) {
      getUsers();
    }
  }

  function rowClicked(record: User, idx: number) {
    console.log(record, idx, "rowClicked");
    editSelectedRow(idx);
  }

  function editSelectedRow(idx: number) {
    selectedRow.value = {
      [idx]: true,
    };
  }

  function cancelUpdate(idx: number) {
    console.log(idx, "cancelUpdate");
    selectedRow.value = {
      [idx]: false,
    };
  }

  async function _updateUser(idx: number, selectedUser: User) {
    const response = await updateUser(selectedUser.companyId, selectedUser.id, selectedUser);
    if(response === "OK"){
      toast.success("User updated successfully")
    } else {
      toast.error("User update failed, please try again")
    }
    selectedRow.value = {
      [idx]: false,
    };
  }

  function inviteUser() {
    emitter.emit("invite-user");
  }

  async function resendInvite(idx: number, selectedUser: User) {
    const newUser: Partial<User> = {};
    newUser.email = selectedUser.email;
    newUser.role = selectedUser.role;
    newUser.status = "Active";
    try {
      const response = await createUser(company.value.companyId, newUser);
      if (response === "success") {
        toast.success(
          newUser.email + " has been resent an invite to create an account!"
        );
      } else {
        toast.error("User resend invite failed, please try again");
      }
    } catch {
      toast.error("User resend invite failed, please try again");
    }
  }

  async function suspendUser(idx: number, selectedUser: User) {
    selectedUser.status = "Inactive";
    const response = await updateUser(selectedUser.companyId, selectedUser.id, selectedUser);
    if(response === "OK"){
      toast.success("User has been suspended")
    } else {
      toast.error("User update failed, please try again")
    }
    selectedRow.value = {
      [idx]: false,
    };
  }

  function sortArray(e: any) {
    userSortSelected.value = e.target.value;
    console.log("sortArray");
    getUsers();
  }

  function changeNumberOfRowsPerPage(event: any) {
    rowsPerPage.value = parseInt(event.target.value);
    currentPage.value = 1;
    getUsers();
  }

  function prevPage() {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
    getUsers();
  }

  function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
    getUsers();
  }

  function goToPage(page: number) {
    currentPage.value = page;
    getUsers()
  }

  onBeforeUnmount(() => {
    emitter.off("newuserinvite");
    tooltipInstances.value.forEach(tooltip => tooltip.dispose());
    tooltipInstances.value = [];
  });

 
  
</script>

<style scoped>
.action-button,
.action-button:focus .action-button:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-check-inline {
  display: inline-flex !important;
}

.edit-dropdown {
  background-color: transparent !important;
}

.custom-select {
  width: 80px;
}

.company-table {
  /* height: calc(100vh - 505px); */
  height: 315px;
  overflow-y: auto;
  cursor: default;
}

.company-table-height {
  height: calc(100vh - 550px);
  cursor: default;
}

.table-header {
  position: sticky;
  top: 0;
  border-top: none;
}

.edit-text {
  width: 160px;
  height: 30px;
  font-size: 12px;
}

.edit-select {
  width: 80px;
  height: 30px;
  font-size: 12px;
}

.labels {
  font-size: 16px;
  align-items: center;
  font-family: "proximanova-bold", sans-serif;
}

.header {
  font-family: "proximanova-bold", sans-serif;
  font-size: 36px;
  border: 0;
  padding: 0;
  display: flex;
}

.b-container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /* padding: 1.25rem; */
}

:deep .table-b-table-default {
  font-family: "proximanova-bold", sans-serif;
  font-size: 16px;
  font-weight: normal !important;
}

:deep .v-align-date {
  padding-top: 0.65rem;
}

:deep .limit-column-width {
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* TODO if all tables follow this style move to main scss file */
:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

.form-control:focus {
  outline: 0 !important;
  box-shadow: none;
  background: #f9f9f9;
}

.search {
  background: #f9f9f9;
  border: 0;
  margin: 0.75rem 0.75rem 0.75rem 0 !important;
}

.search-button {
  background: #f9f9f9;
  color: #898989;
  border: 0;
  outline: 0;
  border-radius: 0.5rem;
  box-shadow: none;
  margin: 0.75rem 0 0.75rem 0.75rem;
}

.search-button:focus,
.search-button:active {
  box-shadow: none !important;
}

@media screen and (max-width: 576px) {
  .company-table {
    height: auto;
  }

  .company-table-height {
    /* height: calc(100vh - 550px); */
    cursor: default;
  }
}
</style>
